<template>
  <div class="details width_1200">
    <!-- <div class="title">三合美术馆</div> -->
    <div class="content" v-html="details.Introduce"></div>
  </div>
</template>
<script>
import { queryArtGallery } from "@/api/fineArts";
export default {
  name: "index",
  components: {},
  computed: {},
  watch: {},
  data () {
    return {
      details: {}
    };
  },
  created () {
    this.init()
  },
  mounted () { },
  methods: {
    async init () {
      const res = await queryArtGallery();
      if (res.success == true) {
        this.details = res.response
      }
    }
  }
};
</script>
<style lang="less" scoped>
.details {
  padding: 15px;
  min-height: 100vh;
  background-color: #fff;
  margin: 30px auto;
  .title {
    font-size: 17px;
    font-weight: bold;
    line-height: 1;
    padding: 20px 0;
    text-align: center;
  }
  /deep/.content {
    font-size: 14px;
    img,
    video {
      max-width: 100%;
    }
  }
}
</style>
